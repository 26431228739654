<template>
  <div
    class="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8"
  >
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <img
        class="mx-auto h-40 w-auto"
        src="https://syion.com/images/logo.png"
        alt="Workflow"
      />
      <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
        Enter code to verify account {{ username }}
      </h2>
    </div>

    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        <form
          class="space-y-6"
          action="#"
          method="POST"
          @submit.prevent="confirmSignUp"
        >
          <div>
            <label for="code" class="block text-sm font-medium text-gray-700">
              Code
            </label>
            <div class="mt-1">
              <input
                id="code"
                name="code"
                type="text"
                v-model="code"
                required
                class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              />
            </div>
          </div>

          <div>
            <button
              type="submit"
              class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Submit
            </button>
            <br />
            <button
              @click="cancel2FA"
              class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-yellow-600 hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { Auth } from "aws-amplify";
export default {
  name: "TwoFA",
  props: ["userId"],
  data() {
    return {
      username: this.userId,
      code: "",
    };
  },
  mounted() {
    if (this.userId) {
      this.username = this.userId;
    }
  },
  methods: {
    async confirmSignUp() {
      try {
        await Auth.confirmSignUp(this.userId, this.code);
        this.$router.push({
          name: "AccountSetUp",
          params: { email: this.userId },
        });
      } catch (error) {
        console.log("error verify", error);
      }
    },
    cancel2FA() {
      try {
        this.$router.push({ name: "Login" });
      } catch (error) {
        alert(error.message);
      }
    },
  },
};
</script>
